




































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getOrderData,
  getOrderDetail,
} from '@/serve/medicalAppointment/orderManage';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
@Component({
  name: 'OrderQuery',
})
export default class OrderQuery extends Vue {
  @Watch('healthDate')
  changeDate(val: string[]) {
    this.form.checkStartTime = val[0];
    this.form.checkEndTime = val[1];
  }
  private dialogVisible = false;
  private detailForm: any = {};
  public form = {
    checkerIdCard: '',
    checkerName: '',
    checkAppointPayStatus: '',
    checkStartTime: '',
    checkEndTime: '',
    jkglChecker: {},
    tenantId: '',
  };
  public page = {
    current: 1,
    size: 10,
  };
  public healthDate = '';
  public orderData = [];
  public total = 0;
  public isAdmin = sessionStorage.getItem('isAdmin');
  public tenantName = '';
  public restaurants = [];
  public created() {
    this.getorderData();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  //获取预约表数据
  public async getorderData(): Promise<void> {
    const dataItem = { ...this.page, ...this.form };
    const res = await getOrderData(this.$changNull(dataItem));
    this.orderData = res.data.records;
    this.total = res.data.total;
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getorderData();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getorderData();
  }
  // 搜索
  public async search() {
    console.log("筛选方法中")
    const value = { ...this.page, ...this.form };
    // const form = this.$changNull(value);
    // if (form.checkerName) {
    //   this.$set(form['jkglChecker'], 'checkerName', form.checkerName);
    // } else {
    //   this.$set(form['jkglChecker'], 'checkerName', null);
    // }
    const res = await getOrderData(this.$changNull(value));
    this.orderData = res.data.records;
    this.total = res.data.total;
  }
  // 重置功能
  public resetForm() {
    this.healthDate="";
    this.form = {
      checkerIdCard: '',
      checkerName: '',
      checkAppointPayStatus: '',
      checkStartTime: '',
      checkEndTime: '',
      jkglChecker: {},

      tenantId: '',
    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getorderData();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
  /** 点击常看详情 */
  private async showDialog(scope: any) {
    const data = await getOrderDetail(scope.row.id);
    this.detailForm = data.data;
    this.dialogVisible = true;
    console.log(this.detailForm);
  }
  /** 点击常看详情的关闭 */
  private canleDialog() {
    this.dialogVisible = false;
  }
  /** 点击常看详情的确定 */
  private confirmDialog() {
    this.dialogVisible = false;
  }
}
