import request from '../../utils/request'
interface Item<T> {
  checkItemName?: T;
  checkItemType?: T;
  diseaseId?: T;
  size: number;
  current: number;
}
//获取预约数据
export function getOrderData(data: Item<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkAppoint/list',
    type: 'params',
    data
  })
}
/** 根据点击预约订单获取订单列表 */
export function getOrderDetail(id: number) {
  return request({
    method: 'GET',
    url: 'manage/checkAppoint/' + id
  })
}

